<template>
    <div class="">
        <!-- <v-img :src="assets.user.userCircle" max-width="120" max-height="120" contain class="mx-auto mt-10"></v-img> -->
        <v-container v-if="error" class="align-center d-flex flex-column px-5 pb-6">
            <v-row class="justify-center">
                <v-col cols="12" class="shrink">
                    <h2 class="text-no-wrap my-6">Log In</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p v-text="error"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large @click="clearError()">Try Again</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else class="align-center d-flex flex-column">
            <form @submit.prevent="submit">
                <v-row :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
                    <v-col class="shrink">
                        <h2 class="text-no-wrap mb-6">Log In</h2>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                            ref="email"
                            v-model="email"
                            type="email"
                            class="pt-0 my-5"
                            :disabled="loading"
                            label="Email Address"
                            placeholder="Please enter your email..."
                            required
                            :rules="[rules.required, rules.email]"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                            ref="password"
                            v-model="password"
                            class="pt-0 my-5"
                            :disabled="loading"
                            label="Password"
                            placeholder="Please enter your password..."
                            required
                            :rules="[rules.required]"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block x-large type="submit" :loading="loading">Log In</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <!-- <v-btn block :to="{ name: 'auth.signup' }" x-large outlined :disabled="loading">Sign Up</v-btn> -->
                        <v-btn class="outline" block @click="$refs.membershipDialog.open()" x-large outlined :disabled="loading"
                            >Sign Up</v-btn
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-btn :to="{ name: 'auth.forgot' }" small text :disabled="loading">
                            <span class="text-capitalize font-weight-regular">Forgotten your password?</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>
        <membership-dialog ref="membershipDialog"></membership-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";
import membershipDialog from "@/components/schedule/membership-dialog.vue";

import { mapState } from "vuex";
export default {
    mixins: [UserMixin],
    meta: {
        titleTemplate: "Login | %s",
    },
    components: {
        membershipDialog,
    },
    data() {
        return {
            errorMessage: "",
            password: "",
            showPassword: false,
            error: "",
            loading: false,
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },
    methods: {
        clearError() {
            this.error = false;
        },
        submit() {
            //Check if there is an application
            var applicationContext = this.application && this.application.authenticationStyle == "application";

            this.loading = true;

            this.$fluro.auth
                .login(
                    {
                        username: this.email,
                        password: this.password,
                    },
                    {
                        bypassInterceptor: true,
                        application: applicationContext, //Sign in to the application rather than Fluro
                    }
                )
                .then(
                    (res) => {
                        var user = res.data;
                        console.log("Login success", user);
                        this.$router.push({ name: "home" });
                    },
                    (err) => {
                        var message = this.$fluro.utils.errorMessage(err);

                        this.errorMessage = message;

                        this.error = "An error occured while trying to log you in. Please try again.";

                        if (message.indexOf("persona") > -1 || message.indexOf("password") > -1) {
                            this.error =
                                "Could not log you in. Please check that your email address and password are correct and try again.";
                        }

                        console.log("ERROR!", message, err);

                        this.loading = false;
                        this.password = "";
                    }
                );
        },
    },
    computed: {
        ...mapState(["assets"]),
        email: {
            set(email) {
                this.$store.commit("email", email);
            },
            get(email) {
                return this.$store.getters.email;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
form {
    max-width: 600px;
    min-width: 280px;
    width: 100%;
}
</style>
