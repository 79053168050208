<template>
    <div class="text-center">
        <v-dialog v-model="dialog" :width="$vuetify.breakpoint.mdAndUp ? '45%' : '90%'" content-class="br-10">
            <template v-slot:activator="{ on, attrs }">
                <div class="p-relative" dark v-bind="attrs" v-on="on"></div>
            </template>

            <v-row no-gutters class="d-flex align-center justify-center card-background">
                <v-col cols="12" class="mt-10 mb-14 text-center" style="font-size: larger">
                    <!-- <div class="text-h4">Sign up</div> -->
                    <h3 class="white--text">Sign up</h3>
                </v-col>
                <!-- <v-col cols="12" class="py-14 px-0 d-flex align-center justify-center">
            <img class="mx-auto shadow" style="border-radius: 5px;" :src="getImage()" width="250" eager />
          </v-col> -->
            </v-row>

            <v-divider></v-divider>
            <v-row no-gutters class="d-flex align-center justify-center mt-n8">
                <!-- <v-spacer />
        <v-col cols="12" sm="5" class="d-flex flex-column align-self-stretch pb-5">
          <v-card class="br-10 ma-2 pa-2 fill-height d-flex flex-column">
            <v-card-title class="d-flex align-center justify-center ">
              Free Member
            </v-card-title>
            <v-card-text>
              <div class="py-4 text-subtitle-1 d-flex align-center ">
                <v-icon class="mx-3" color="primary">fa-solid fa-check</v-icon>
                <div>7-day Free Trial</div>
              </div>

              <div class="py-4 text-subtitle-1 d-flex align-center ">
                <v-icon class="mx-3" color="primary">fa-solid fa-check</v-icon>
                <div>Downloadable Visual Schedules (PDFs)</div>
              </div>

              <div class="py-4 text-subtitle-1 d-flex align-center ">
                <v-icon class="mx-3" color="primary">fa-solid fa-check</v-icon>
                <div>Updates of latest books</div>
              </div>
            </v-card-text>
            <v-spacer />
            <v-card-actions class="d-flex align-center justify-center py-8">
              <v-btn dark color="primary" height="60" class="px-8 " @click="$router.push('/auth/signup')">
                <span class="text-h6 font-weight-bold">Get Started</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-spacer /> -->
                <v-col cols="12" class="d-flex flex-column align-center pb-5">
                    <v-card class="br-10 ma-2 pa-12 fill-height d-flex flex-column" style="width: 80%">
                        <v-card-title class="d-flex align-center justify-center"> Red Sparrow Member </v-card-title>
                        <v-card-text>
                            <div class="py-4 text-subtitle-1 d-flex align-center">
                                <v-icon class="mx-3" color="primary">fa-solid fa-check</v-icon>
                                <div>Access to our full library of Interactive Social Stories</div>
                            </div>

                            <div class="py-4 text-subtitle-1 d-flex align-center">
                                <v-icon class="mx-3" color="primary">fa-solid fa-check</v-icon>
                                <div>Access to the full version of Schedule Builder</div>
                            </div>

                            <div class="py-4 text-subtitle-1 d-flex align-center">
                                <v-icon class="mx-3" color="primary">fa-solid fa-check</v-icon>
                                <div>Access to all images in the Red Sparrow Library for the Schedule Builder</div>
                            </div>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="d-flex align-center justify-center py-8">
                            <v-btn dark color="primary" height="60" class="px-8" @click="$router.push('/account/payment')">
                                <span class="text-h6 font-weight-bold">Get Started</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-spacer />
            </v-row>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "BookTile",
    data() {
        return {
            dialog: false,
        };
    },
    components: {},

    props: ["item"],
    methods: {
        getImage(image) {
            const url = `${this.$fluro.asset.imageUrl(image)}&noRedirect=true`;

            // console.log("Attempting to get Book Cover:", url);
            return url;
        },
        open() {
            this.dialog = true;
        },
    },
};
</script>

<style lang="scss">
.br-10 {
    border-radius: 10px;
}
.shadow {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 5px 4px 5px 0px;
}
.card-background {
    background-color: var(--v-primary-base);
}
</style>
